import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Paragraph from "../components/sections/Paragraph/Paragraph"
import { LinkStyle } from "../components/generic/AnyLink/AnyLink"
import { ComponentNavigationLink } from "../types/generated/strapi"
import ResponsiveLink from "../components/generic/AnyLink/ResponsiveLink"
import Title from "../components/generic/Text/Title"
import { G } from "../components/Workbench/Components"

const toHomeButton: ComponentNavigationLink = {
  id: "",
  _id: "",
  Link: "/",
}

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <Paragraph
      Title={{
        _id: "",
        id: "",
        Text: "Page Not Found",
        Subtext: "404",
      }}
      Text="Unfortunately the page you are looking for cannot be found. It could be
      that you have entered an incorrect or outdated URL. Please check, paying
      particular attention to upper and lower case letters, dots or forward
      slashes. Then try again. Alternatively, you can find the content you are
      looking for by going first to our homepage."
      CTASecondary={{
        id: "",
        _id: "",
        TextDesktop: "Go to home page",
        TextMobile: "Go to home page",
        Link: "/",
      }}
    />
  </Layout>
)

export default NotFoundPage
